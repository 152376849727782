import { render, staticRenderFns } from "./select.vue?vue&type=template&id=9b242ac8&scoped=true&"
import script from "./select.vue?vue&type=script&lang=js&"
export * from "./select.vue?vue&type=script&lang=js&"
import style0 from "./select.vue?vue&type=style&index=0&id=9b242ac8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b242ac8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RostIcon: require('/home/ilya/app/rost_web_frontend/components/rost/icon.vue').default,RostBtn: require('/home/ilya/app/rost_web_frontend/components/rost/btn.vue').default,RostCheckRadioList: require('/home/ilya/app/rost_web_frontend/components/rost/check-radio-list.vue').default,AppBottomSheet: require('/home/ilya/app/rost_web_frontend/components/AppBottomSheet.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VDivider,VListItem,VSelect})
