/* eslint-disable camelcase */
import { normalizeToTableOrder } from '@/modules/orders/Entities/TableOrder'
import { normalizeToSingleOrder } from '@/modules/orders/Entities/SingleOrder'
import { normalizeToTableCustomer } from '@/modules/customers/Entities/TableCustomer'
import { normalizeToSingleCustomer } from '@/modules/customers/Entities/SingleCustomer'
import { normalizeToTableEmployee } from '~/modules/staff-employees/entities/TableEmployee'
import { normalizeToSingleEmployee } from '~/modules/staff-employees/entities/SingleEmployee'

import { normalizeToTableProduct } from '@/modules/products/Entities/TableProduct'
import { normalizeToSingleProduct } from '@/modules/products/Entities/SingleProduct'

import { normalizeToTableAccounting } from '@/modules/accounting/Entities/TableAccounting'
import { normalizeToSingleAccounting } from '@/modules/accounting/Entities/SingleAccounting'

import { normalizeToTableBonusRequests } from '@/modules/requests/Entities/TableBonusRequest'
import { normalizeToSingleWriteOffRequest } from '@/modules/requests/Entities/SingleWriteOffRequest'

import { normalizeToTableRaiseRequests } from '@/modules/requests/Entities/TableRaiseRequest'

import { normalizeToSingleProductGroup } from '@/modules/product-groups/Entities/SingleGroup'

import { normalizeToTableNotification } from '@/modules/push/Entities/TableNotification'

import { normalizeToTableСampaign } from '@/modules/market-company/Entities/TableСampaign'
import { normalizeToSingleСampaign } from '@/modules/market-company/Entities/SingleСampaign'

import { normalizeToTableStories } from '@/modules/stories/Entities/TableStories'
import { normalizeToSingleStory } from '@/modules/stories/Entities/SingleStory'

import { normalizeToDocumentCollenction } from '@/modules/employees-documents/Entities/DocumentCollenction'
import { normalizeToSingleCollenction } from '@/modules/employees-documents/Entities/SingleCollection'

import { getProductImg, normalizeUser } from '@/utils/normalize-helpers'
import createRepository from '@/api/repository'
import { normalizeToTableAudience } from '~/modules/audience/Entities/TableAudience'
import { normalizeToSingleAudience } from '~/modules/audience/Entities/SingleAudience'
import { normalizeToTableReferral } from '~/modules/referrals/Entities/TableReferral'
import { normalizeMonthList } from '~/modules/cluster-offer/helpers/filters'
import { normalizeToTableGcCustomer } from '~/modules/gc-customers/Entities/TableCustomer'
import { normalizeToSingleGcCustomer } from '~/modules/gc-customers/Entities/SingleCustomer'
import { useAppFetch } from '~/compositions/useAppFetch'
import { normalizeToTableGcOrder } from '~/modules/gc-orders/entities/TableOrders'
import { normalizeToSingleGcOrder } from '~/modules/gc-orders/entities/SingleOrder'
const cacheControl = new Date().toTimeString().split(' ')[0]

const indexFormater = (path, formatFn, $axios) => {
  return async (params) => {
    let res
    await useAppFetch().makeFetch(
      async () => (res = await $axios.$get(path, params))
    )

    if (res.status.code === '' || res.status.code === 'OK') {
      const items = res.data.items || res.data
      return {
        status: res.status,
        data: {
          ...res.data,
          items: items.map(formatFn),
        },
      }
    }
    return res
  }
}

const showFormater = (path, formatFn, $axios) => {
  return async (id, params) => {
    const res = await $axios.$get(`${path}${id}/`, params)
    if (res.status.code === '' || res.status.code === 'OK') {
      return {
        status: res.status,
        data: {
          ...formatFn(res.data),
        },
      }
    }
    return res
  }
}

const indexIdsPathFormater = (path, formatFn, $axios) => {
  return async (id, params) => {
    if (params && params.params) {
      params = params.params
    }
    const fullPath = typeof path === 'string' ? `${path}${id}/` : path(id)
    const res = await $axios.$get(fullPath, {
      params,
    })
    let items = res.data.items || res.data

    /* TODO must fix on backend
    TEST CASE https://devapi.beyondviolet.com/sys/v1/chips/promos/4/products/
*/
    if (!Array.isArray(items)) {
      items = []
    }

    if (res.status.code === 'OK' || res.status.code === '') {
      return {
        status: res.status,
        data: {
          ...res.data,
          items: items.map(formatFn),
        },
      }
    }
    return res
  }
}

const usersGeneralApi = (
  path,
  { tableNormalizer, singleNormalizer, isEmployee },
  $axios
) => {
  return {
    index: indexFormater(path, tableNormalizer, $axios),
    show: showFormater(path, singleNormalizer, $axios),
    async getOrders(id, params) {
      const res = await $axios.$get(`${path}${id}/orders/`, { params })
      if (res.status.code === 'OK' || res.status.code === '') {
        const resp = {
          status: res.status,
          data: {
            ...res.data,
            items: res.data.items.map(normalizeToTableOrder),
            rows: res.data.items.map(normalizeToTableOrder),
          },
        }

        return resp
      }
      return res
    },
    getFeedbacks(id, params) {
      return $axios.$get(`${path}${id}/feedbacks/`, {
        params,
      })
    },
    getDevices(id, params) {
      return $axios.$get(`${path}${id}/devices/`, {
        params,
      })
    },
    getBonusTransactions(id, params) {
      const pathEnd = isEmployee ? '/transactions/' : '/bonus_transactions/'
      return $axios.$get(`${path}${id}${pathEnd}`, {
        params,
      })
    },
    createBonusTransactions(id, params) {
      const pathEnd = isEmployee ? '/transactions/' : '/bonus_transactions/'
      return $axios.$post(`${path}${id}${pathEnd}`, params)
    },
  }
}

export default function ({ $axios, $config }, inject) {
  const repositoryWithAxios = createRepository($axios)
  inject('authRepository', {
    getCode(params) {
      return $axios.$post('/oauth/authorize/', params, {
        baseURL: $config.baseURLSSO,
      })
    },
  })
  inject('systemDeliveryPolygonsRepository', {
    getPolygons() {
      return $axios.$get('/test/orders/map/', {
        baseURL: $config.baseURLShopping,
      })
    },
    savePolygon(payload) {
      return $axios.$post('/test/orders/boundary/', payload, {
        baseURL: $config.baseURLShopping,
      })
    },
  })

  inject('systemOrderWayRepository', {
    orderWay(id) {
      return $axios.$get(`/delivery/orders/${id}/way`)
    },
  })

  inject('systemOrdersFiltersRepository', {
    stores() {
      return $axios.$get(`/sys/orders/v1/filters/stores/`)
    },
    collectors() {
      return $axios.$get(`/sys/orders/v1/filters/collector/`)
    },
    couriers() {
      return $axios.$get(`/sys/orders/v1/filters/courier/`)
    },
  })

  inject('systemOrdersRepository', {
    ...repositoryWithAxios('/sys/orders/v1/'),
    index: indexFormater('/sys/orders/v1/', normalizeToTableOrder, $axios),
    show: showFormater('/sys/orders/v1/', normalizeToSingleOrder, $axios),
    async lines(id) {
      const res = await $axios.$get(`/sys/orders/v1/${id}/lines/`)
      return res
    },
    async assignUser(id, payload) {
      const res = await $axios.$patch(`/sys/orders/v1/${id}/employee/`, payload)
      return res
    },
    getPaymentLink(id) {
      return $axios.$post(`/sys/orders/v1/${id}/payments/`)
    },
    updateLines(id, payload) {
      return $axios.$patch(`/sys/orders/v1/${id}/lines/`, payload)
    },
    createLines(id, payload) {
      return $axios.$post(`/sys/orders/v1/${id}/lines/`, payload)
    },
  })

  inject('systemStaffEmployeesRepository', {
    index: indexFormater('/staff/employees/', normalizeToTableEmployee, $axios),
    show: showFormater(`/staff/employees/`, normalizeToSingleEmployee, $axios),
    editEmployee(id, payload) {
      return $axios.$patch(`/staff/employees/${id}/`, payload)
    },
    addEmployee(payload) {
      return $axios.$post(`/staff/employees/`, payload)
    },
    getPerformanceIndicators(id, params) {
      return $axios.$get(`/staff/employees/${id}/kpi/`, { params })
    },
    getPaymentsDocuments(id, params) {
      return $axios.$get(`/staff/employees/${id}/payments/`, { params })
    },
    getPaymentDetails(id, paymentId) {
      return $axios.$get(`/staff/employees/${id}/payments/${paymentId}/`)
    },
    getDevices(id, params) {
      return $axios.$get(`/staff/employees/${id}/devices/`, { params })
    },
    getTransactions(id, params) {
      return $axios.$get(`/staff/employees/${id}/transactions/`, { params })
    },
    createBonusTransactions(id, payload) {
      return $axios.$post(`/staff/employees/${id}/transactions/`, payload)
    },
    getOrders(id, params) {
      return $axios.$get(`/staff/employees/${id}/orders/`, { params })
    },
    getRoles() {
      return $axios.$get(`/staff/employees/roles/`)
    },
    addContractor(id, payload) {
      return $axios.$post(`/staff/employees/${id}/add_contractor/`, payload)
    },
  })

  inject('systemEmployeesRepository', {
    ...repositoryWithAxios('/sys/employees/v1/'),
    ...usersGeneralApi(
      '/sys/employees/v1/',
      {
        tableNormalizer: normalizeToTableEmployee,
        singleNormalizer: normalizeToSingleEmployee,
        isEmployee: true,
      },
      $axios
    ),
    async payments(id, params) {
      const res = await $axios.$get(`/sys/employees/v1/${id}/payments/`, {
        params,
      })
      return res
    },
    roles() {
      return $axios.$get(`/sys/employees/v1/roles/`)
    },
    personal(id) {
      return $axios.$get(`/sys/employees/v1/${id}/personal/`)
    },
    links(id) {
      return $axios.$get(`/sys/employees/v1/${id}/links/`)
    },
    details(id) {
      return $axios.$get(`/sys/employees/v1/${id}/details/`)
    },
    documents(id) {
      return $axios.$get(`/sys/employees/v1/${id}/personal_documents/`)
    },
    raise: indexFormater(
      '/sys/employees/v1/raise/',
      normalizeToTableRaiseRequests,
      $axios
    ),
    raiseStatusUpdate(id, payload) {
      return $axios.$patch(`/sys/employees/v1/raise/${id}/`, payload)
    },

    // {
    //   return $axios.$get(`/sys/employees/v1/raise/`, { params })
    // },
    bonusTransactions: indexFormater(
      '/sys/employees/v1/bonus/writeoff_requests/',
      normalizeToTableBonusRequests,
      $axios
    ),
    writeoffRequest: indexIdsPathFormater(
      (id) => `/sys/employees/v1/bonus/writeoff_requests/${id}/`,
      normalizeToSingleWriteOffRequest,
      $axios
    ),

    raiseAndBonusTransactionsStatuses() {
      return $axios.$get(`/sys/employees/v1/statuses/`)
    },
    bonusRequestStatusUpdate(requestId, payload) {
      return $axios.$patch(
        `/sys/employees/v1/bonus/writeoff_requests/${requestId}/`,
        payload
      )
    },
  })

  inject('systemCustomersRepository', {
    ...repositoryWithAxios('/sys/customers/v1/'),
    ...usersGeneralApi(
      '/sys/customers/v1/',
      {
        tableNormalizer: normalizeToTableCustomer,
        singleNormalizer: normalizeToSingleCustomer,
        isEmployee: false,
      },
      $axios
    ),
    getEditFormEmployer(id) {
      return $axios.$get(`/sys/customers/v1/${id}/`)
    },
    getDiscounts: indexIdsPathFormater(
      (id) => `/sys/customers/v1/${id}/discounts/`,
      (el) => {
        return {
          ...el,
          amount: el.amount * 100,
          img: getProductImg(el.product.img),
        }
      },
      $axios
    ),

    createDiscount(id, params) {
      return $axios.$post(`/sys/customers/v1/${id}/discounts/`, params)
    },
    getReceipts(id, params) {
      return $axios.$get(`/sys/customers/v1/${id}/receipts/`, {
        params,
      })
    },

    getAnalytics(id, params) {
      return $axios.$get(`/sys/customers/v1/${id}/analytics/`, {
        params,
      })
    },
  })
  // сотрудники
  inject('systemGcCustomersRepository', {
    ...repositoryWithAxios('/sys/customers/'),
    index: indexFormater('/sys/customers/', normalizeToTableGcCustomer, $axios),
    show: showFormater('/sys/customers/', normalizeToSingleGcCustomer, $axios),
    getChecks(customerUuid, params) {
      return $axios.$get(`/sys/customers/${customerUuid}/receipts/`, { params })
    },
    getCheckByUuid(customerUuid, checkUuid, params) {
      return $axios.$get(
        `/sys/customers/${customerUuid}/receipts/${checkUuid}/`,
        { params }
      )
    },
    getCompliments(customerUuid, params) {
      return $axios.$get(`/sys/customers/${customerUuid}/compliments/`, {
        params,
      })
    },
    addCompliment(customerUuid, payload) {
      return $axios.$post(
        `/sys/customers/${customerUuid}/compliments/`,
        payload
      )
    },
    getDiscounts(customerUuid, cardId, params) {
      return $axios.$get(
        `/sys/customers/${customerUuid}/${cardId}/personal_offers/`,
        {
          params,
        }
      )
    },
    giveDiscount(customerUuid, cardId, payload) {
      return $axios.$post(
        `/sys/customers/${customerUuid}/${cardId}/personal_offers/`,
        payload
      )
    },
    getLoymaxUser(customerUuid, params) {
      return $axios.$get(`/sys/customers/${customerUuid}/loymax/`, {
        params,
      })
    },
    addLoyaltyCard(customerUuid, payload) {
      console.log(payload)
      return $axios.$post(
        `/sys/loyalty/v1/users/${customerUuid}/cards/`,
        payload
      )
    },
  })

  // заказы
  inject('systemGcOrdersRepository', {
    ...repositoryWithAxios('/sys/orders/v2/'),
    index: indexFormater('/sys/orders/v2/', normalizeToTableGcOrder, $axios),
    show: showFormater('/sys/orders/v2/', normalizeToSingleGcOrder, $axios),
    stores(params) {
      return $axios.$get(`/sys/orders/v2/stores/`, params)
    },
    products: indexFormater(
      '/sys/orders/v2/products/',
      normalizeToTableProduct,
      $axios
    ),
    createHorecaOrder(payload) {
      return $axios.$post(`/sys/orders/v2/`, payload)
    },
    timeSlots(storeId, warehouseId, date) {
      return $axios.$get(
        `/sys/orders/v2/time_slots/${storeId}/${warehouseId}/${date}/`
      )
    },
    payments(orderId) {
      return $axios.$get(`/sys/orders/v2/${orderId}/payments/`)
    },
  })

  inject('systemProductsRepository', {
    index: indexFormater(
      '/sys/erp/products-spec/v1/products/',
      normalizeToTableProduct,
      $axios
    ),
    show: showFormater(
      '/sys/erp/products-spec/v1/products/',
      normalizeToSingleProduct,
      $axios
    ),
    partnerProducts: indexIdsPathFormater(
      (userUuid) => `/sys/erp/products-spec/v1/products/${userUuid}`,
      normalizeToTableProduct,
      $axios
    ),
    loadImg(productId, payload) {
      return $axios.$post(
        `/sys/products/v1/${productId}/upload_image/`,
        payload
      )
    },
    saveProduct(productId, data) {
      return $axios.$patch(`/sys/erp/v1/products/${productId}/`, data)
    },
    getSummary() {
      return $axios.$get(`/sys/erp/products-spec/v1/summary/`)
    },
    classifiers(params) {
      return $axios.$get(
        `/sys/erp/products-spec/v1/products/classifier/`,
        params
      )
    },
    getTags(productId) {
      return $axios.$get(
        `/sys/erp/products-spec/v1/products/${productId}/tags/`
      )
    },
    updateTags(productId, payload) {
      return $axios.$put(
        `/sys/erp/products-spec/v1/products/${productId}/tags/`,
        payload
      )
    },
    // branding
    getBranding(productId) {
      return $axios.$get(
        `/sys/erp/products-spec/v1/products/${productId}/branding/`
      )
    },
    updateBranding(productId, payload) {
      return $axios.$put(
        `/sys/erp/products-spec/v1/products/${productId}/branding/`,
        payload
      )
    },
    manufacturers(params) {
      return $axios.$get(
        `/sys/erp/products-spec/v1/products/manufacturers/`,
        params
      )
    },
    brands(params) {
      return $axios.$get(`/sys/erp/products-spec/v1/products/brands/`, params)
    },
    addBrand(payload) {
      return $axios.$post(`/sys/erp/products-spec/v1/products/brands/`, payload)
    },
    countries(params) {
      return $axios.$get(
        `/sys/erp/products-spec/v1/products/countries/`,
        params
      )
    },
    productLines(brandId, params) {
      return $axios.$get(
        `/sys/erp/products-spec/v1/products/brands/${brandId}/product_lines/`,
        params
      )
    },
    addProdLine(brandId, payload) {
      return $axios.$post(
        `/sys/erp/products-spec/v1/products/brands/${brandId}/product_lines/`,
        payload
      )
    },
  })

  inject('systemMarketplaseFeedbacksRepository', {
    getDescription(params) {
      return $axios.$get(`/sys/marketplace/v1/feedbacks/filters/`, {
        params,
      })
    },
    getStores(params) {
      return $axios.$get(`/sys/marketplace/v1/feedbacks/stores/`, {
        params,
      })
    },
    getReasonsTags(params) {
      return $axios.$get(`/sys/marketplace/v1/feedbacks/filters/reasons/`, {
        params,
      })
    },
    getProducts(store_id, params) {
      return $axios.$get(
        `/sys/marketplace/v1/feedbacks/stores/${store_id}/products/`,
        {
          params,
        }
      )
    },
    getProduct(urlData, params) {
      return $axios.$get(
        `/sys/marketplace/v1/feedbacks/stores/${urlData.store_id}/products/${urlData.product_id}/info/`,
        { params }
      )
    },
    getComplaints(urlData, params) {
      return $axios.$get(
        `/sys/marketplace/v1/feedbacks/stores/${urlData.store_id}/products/${urlData.product_id}/`,
        { params }
      )
    },
    closeComplaints(payload) {
      return $axios.$put(`/sys/marketplace/v1/feedbacks/close/`, payload)
    },
  })

  inject('systemUsersRepository', {
    ...repositoryWithAxios('/sys/users/v1/'),
    index: indexFormater(
      '/sys/users/v1/',
      (user) => ({
        ...normalizeUser(user),
        // name: `${user.personal.first_name} ${user.personal.last_name}`,
        ...user,
      }),
      $axios
    ),
    heatmap(id) {
      return $axios.$get(`/sys/erp/v1/users/${id}/heatmap/`)
    },
  })

  inject('systemAccountingRepository', {
    ...repositoryWithAxios('/sys/accounting/v1/salary_documents/'),
    index: indexFormater(
      '/sys/accounting/v1/salary_documents/',
      normalizeToTableAccounting,
      $axios
    ),
    show: showFormater(
      '/sys/accounting/v1/salary_documents/',
      normalizeToSingleAccounting,
      $axios
    ),
    fines() {
      return $axios.$get(`/sys/accounting/v1/fines/`)
    },
    payments() {
      return $axios.$get(`/sys/accounting/v1/payments/`)
    },
    paymentPeriods: indexFormater(
      '/sys/accounting/v1/payment_periods/',
      (el) => {
        return {
          period: el.period
            .map((el) => el.split('-').reverse().join('.'))
            .join(' - '),
          id: el.id,
        }
      },
      $axios
    ),
    // () {
    //   return $axios.$get(`/sys/accounting/payment_periods/`)
    // },
    lines: indexIdsPathFormater(
      (id) => `/sys/accounting/v1/salary_documents/${id}/lines/`,
      (el) => {
        return {
          id: el.id,
          description: el.data.description || el.type.label,
          count: el.data.count,
          unit: el.data.unit,
          rate: el.data.rate,
          amount: el.amount,
          kind: el.kind,
          type: el.type.value,
        }
      },
      $axios
    ),
    updateLines(id, payload) {
      return $axios.$patch(
        `/sys/accounting/v1/salary_documents/${id}/lines/`,
        payload
      )
    },
    deleteLines(id, payload) {
      return $axios.$delete(
        `/sys/accounting/v1/salary_documents/${id}/lines/`,
        {
          params: payload,
        }
      )
    },
    createLines(id, payload) {
      return $axios.$post(
        `/sys/accounting/v1/salary_documents/${id}/lines/`,
        payload
      )
    },
    getCapabilities() {
      return $axios.$get(`/system/users/capabilities/`)
    },
  })

  inject('systemAnalyticsRepository', {
    // getSuplierInventory(params) {
    //   return $axios.$get(`/sys/v1/analytics/suppliers/inventory-statistics/`, {
    //     params,
    //   })
    // },
    // getSuplierSales(params) {
    //   return $axios.$get(`/sys/v1/analytics/suppliers/sales-statistics/`, {
    //     params,
    //   })
    // },

    getProductsCategories(params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/products-classifiers/`, {
        params,
        baseURL: $config.baseURLAnalytic,
      })
    },
    getProducts(params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/products/`, {
        params,
        baseURL: $config.baseURLAnalytic,
      })
    },
    getCities(params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/cities/`, {
        params,
        baseURL: $config.baseURLAnalytic,
      })
    },
    getStores(params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/stores/`, {
        params,
        baseURL: $config.baseURLAnalytic,
      })
    },
    getHeatmap(params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/data/heatmap/`, {
        params,
        baseURL: $config.baseURLAnalytic,
      })
    },
    getAnomalSku(params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/products/`, {
        params,
        baseURL: $config.baseURLAnalytic,
      })
    },
    // getSalesByProduct(params) {
    //   return $axios.$get(
    //     `/sys/v1/analytics/suppliers/sales-by-product-classifiers-statistics/`,
    //     {
    //       params,
    //     }
    //   )
    // },
    // localStorage.getItem('auth._token.local')
    getConfig() {
      return $axios.$get(`/sys/analytics/v1/suppliers/config/`, {
        params: { cacheControl },
        baseURL: $config.baseURLAnalytic,
      })
    },
    getCharts(params) {
      return $axios.$get(`/sys/analytics/v1/suppliers/charts/`, {
        params: { ...params, cacheControl },
        baseURL: $config.baseURLAnalytic,
      })
    },
    getSingleChart(chartCode, params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/charts/${chartCode}/`, {
        params: { ...params, cacheControl },
        baseURL: $config.baseURLAnalytic,
      })
    },
    getTagCloud(params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/products-tags/`, {
        params: { ...params, cacheControl },
        baseURL: $config.baseURLAnalytic,
      })
    },
    getFilters(params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/filters/`, {
        params: { ...params, cacheControl },
        baseURL: $config.baseURLAnalytic,
      })
    },
    getStoresTop(params) {
      return $axios.$get(`/sys/v1/analytics/suppliers/tables/top-stores/`, {
        params: { ...params, cacheControl },
        baseURL: $config.baseURLAnalytic,
      })
    },
    downloadExcel(chartCode, params) {
      return $axios
        .$get(`/sys/v1/analytics/suppliers/charts/${chartCode}/excel/`, {
          params: { ...params, cacheControl },
          responseType: 'blob',
          baseURL: $config.baseURLAnalytic,
        })
        .then((response) => {
          const blob = URL.createObjectURL(
            new Blob([response], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = blob
          link.download = `${chartCode}.xlsx`
          link.click()
        })
        .catch(async (error) => {
          error.response.data = JSON.parse(await error.response.data.text())
          throw error
        })
    },
    downloadReportByPath(path, code, params) {
      return $axios
        .$get(`/sys/v1/analytics/suppliers/${path}`, {
          params: { ...params, cacheControl },
          responseType: 'blob',
          baseURL: $config.baseURLAnalytic,
        })
        .then((response) => {
          const blob = URL.createObjectURL(
            new Blob([response], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = blob
          link.download = `${code}.xlsx`
          link.click()
        })
        .catch(async (error) => {
          error.response.data = JSON.parse(await error.response.data.text())
          throw error
        })
    },
    getAnomalous(params) {
      return $axios.$get(
        `/sys/v1/analytics/suppliers/tables/anomalous-products/`,
        {
          params: { ...params, cacheControl },
          baseURL: $config.baseURLAnalytic,
        }
      )
    },
  })

  inject('systemProductGroupsRepository', {
    ...repositoryWithAxios('/sys/marketing/product-groups/v1/groups/'),

    show: showFormater(
      '/sys/marketing/product-groups/v1/groups/',
      normalizeToSingleProductGroup,
      $axios
    ),
    createProductGroup(payload) {
      return $axios.$post(`/sys/marketing/product-groups/v1/groups/`, payload)
    },
    editProductGroup(id, payload) {
      return $axios.$patch(
        `/sys/marketing/product-groups/v1/groups/${id}/`,
        payload
      )
    },
    deleteProductGroup(id) {
      return $axios.$delete(`/sys/marketing/product-groups/v1/groups/${id}/`)
    },
    getProducts(id, payload) {
      return $axios.$get(
        `/sys/marketing/product-groups/v1/groups/${id}/products/`,
        payload
      )
    },
    addProductsToGroup(id, payload) {
      return $axios.$post(
        `/sys/marketing/product-groups/v1/groups/${id}/products/`,
        payload
      )
    },
    removeProductsFromGroup(id, payload) {
      return $axios.$delete(
        `/sys/marketing/product-groups/v1/groups/${id}/products/`,
        { data: payload }
      )
    },
  })

  inject('systemMarketingRepository', {
    ...repositoryWithAxios('/sys/marketing/v1/campaigns/'),
    index: indexFormater(
      '/sys/marketing/v1/campaigns/',
      normalizeToTableСampaign,
      $axios
    ),

    show: showFormater(
      '/sys/marketing/v1/campaigns/',
      normalizeToSingleСampaign,
      $axios
    ),
    audience() {
      return $axios.$get(`/sys/marketing/v1/audience/`)
    },
    async campaignsTypes() {
      const res = await $axios.$get(`/sys/marketing/v1/campaigns/types/`)
      return res
    },
    targetGroupsFilters() {
      return $axios.$get(`/sys/marketing/v1/target_groups/filters/`)
    },
    activateCampaign(id) {
      return $axios.$patch(`/sys/marketing/v1/campaigns/${id}/active_state/`)
    },
    deactivateCampaign(id) {
      return $axios.$delete(`/sys/marketing/v1/campaigns/${id}/active_state/`)
    },
    approveStatus(id) {
      return $axios.$post(`/sys/marketing/v1/campaigns/${id}/approve/`)
    },
    cancelStatus(id) {
      return $axios.$post(`/sys/marketing/v1/campaigns/${id}/cancel/`)
    },
    completeStatus(id) {
      return $axios.$post(`/sys/marketing/v1/campaigns/${id}/mark-completed/`)
    },
    requestApprovalStatus(id) {
      return $axios.$post(`/sys/marketing/v1/campaigns/${id}/request-approval/`)
    },
    requestDraftStatus(id) {
      return $axios.$post(`/sys/marketing/v1/campaigns/${id}/revoke-request/`)
    },
    addProducts(id, payload) {
      return $axios.$post(
        `/sys/marketing/v1/campaigns/${id}/products/`,
        payload
      )
    },
    deleteProduct(id, code) {
      return $axios.$delete(`/sys/marketing/v1/campaigns/${id}/products/`, {
        params: {
          code,
        },
      })
    },
    downloadExcel(campaign_id) {
      return $axios
        .$get(
          `/sys/marketing/v1/campaigns/${campaign_id}/personal_offers/excel/`,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const blob = URL.createObjectURL(
            new Blob([response], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = blob
          link.download = `campaign_${campaign_id}_personal_offers.xlsx`
          link.click()
        })
    },
    getRetailers() {
      return $axios.$get(`/sys/marketing/v1/campaigns/retailers/`)
    },
  })

  inject('systemChipsRepository', {
    ...repositoryWithAxios('/sys/chips/v1/promos/'),
    chipsProducts: indexIdsPathFormater(
      (id) => `/sys/chips/v1/promos/${id}/products/`,
      (el) => ({
        ...el,
        img: getProductImg(el.img),
      }),
      $axios
    ),
    updateProducts(promo_id, payload) {
      return $axios.$patch(
        `/sys/chips/v1/promos/${promo_id}/products/`,
        payload
      )
    },
    deleteProducts(promo_id, sku) {
      return $axios.$delete(`/sys/chips/v1/promos/${promo_id}/products/`, {
        params: {
          sku,
        },
      })
    },
    createProducts(id, payload) {
      return $axios.$post(`/sys/chips/v1/promos/${id}/products/`, payload)
    },

    updateOffer(promo_id, payload) {
      return $axios.$patch(`/sys/chips/v1/promos/${promo_id}/offers/`, payload)
    },
    // deleteOffers(promo_id, offer_id) {
    //   return $axios.$delete(`/sys/chips/v1/promos/${promo_id}/offers/`, {
    //     params: {
    //       offer_id,
    //     },
    //   })
    // },
    createOffer(id, payload) {
      return $axios.$post(`/sys/chips/v1/promos/${id}/offers/`, payload)
    },
  })

  inject('imageRepository', {
    loadImage(payload) {
      return $axios.$post(`https://static.beyondviolet.com/upload3/`, payload)
    },
  })

  inject('videoRepository', {
    loadVideo(payload) {
      return $axios.$post(
        `https://static.beyondviolet.com/upload/video/`,
        payload
      )
    },
  })

  inject('systemPushRepository', {
    ...repositoryWithAxios('/sys/marketing/v1/notifications/push-mailings/'),
    index: indexFormater(
      '/sys/marketing/v1/notifications/push-mailings/',
      normalizeToTableNotification,
      $axios
    ),

    targetsApps() {
      return $axios.$get(`/sys/marketing/v1/notifications/targets/apps/`)
    },
    targetsCities() {
      return $axios.$get(`/sys/marketing/v1/notifications/targets/cities/`)
    },
    redirects() {
      return $axios.$get(`/sys/marketing/v1/notifications/redirects/`)
    },

    changeState(id, payload) {
      return $axios.$put(
        `/sys/marketing/v1/notifications/push-mailings/${id}/state/`,
        { state: payload }
      )
    },
  })

  inject('systemStoriesRepository', {
    ...repositoryWithAxios('/sys/marketing/v1/stories/'),
    index: indexFormater(
      '/sys/marketing/v1/stories/',
      normalizeToTableStories,
      $axios
    ),
    show: showFormater(
      '/sys/marketing/v1/stories/',
      normalizeToSingleStory,
      $axios
    ),
    getActiveStories() {
      return $axios.$get(`/sys/marketing/v1/stories/active/`)
    },
    updateActiveStories(payload) {
      return $axios.$put(
        `/sys/marketing/v1/stories/active/sort_order/`,
        payload
      )
    },
    reorderSlides(id, payload) {
      return $axios.$put(
        `/sys/marketing/v1/stories/${id}/slides/sort_order/`,
        payload
      )
    },
    filters(id, payload) {
      return $axios.$get(`/sys/marketing/v1/stories/filters/`, payload)
    },

    getSlide(storyid, slideId) {
      return $axios.$get(
        `/sys/marketing/v1/stories/${storyid}/slides/${slideId}/`
      )
    },

    slideFilters(id, payload) {
      return $axios.$get(`/sys/marketing/v1/stories/slides/filters/`, payload)
    },

    createSlide(id, payload) {
      return $axios.$post(`/sys/marketing/v1/stories/${id}/slides/`, payload)
    },
    editSlide(storyid, slideId, payload) {
      return $axios.$patch(
        `/sys/marketing/v1/stories/${storyid}/slides/${slideId}/`,
        payload
      )
    },
    deleteSlide(storyid, slideId, payload) {
      return $axios.$delete(
        `/sys/marketing/v1/stories/${storyid}/slides/${slideId}/`,
        payload
      )
    },
  })

  inject('systemDocumentsRepository', {
    ...repositoryWithAxios('/documents/'),
    index: indexFormater('/documents/', normalizeToDocumentCollenction, $axios),

    getSingleCollection: indexIdsPathFormater(
      (id) => `/documents/${id}/`,
      normalizeToSingleCollenction,
      $axios
    ),
    getReportsConfig() {
      return $axios.$get(`/sys/erp/v1/reports/config/`)
    },
    downloadExcel(url, name, params) {
      return $axios
        .$get(`${url}`, {
          params: { ...params },
          responseType: 'blob',
          baseURL: 'https://admin.beyondviolet.com/reports',
        })
        .then((response) => {
          const blob = URL.createObjectURL(
            new Blob([response], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = blob
          link.download = `${name}.xlsx`
          link.click()
        })
        .catch(async (error) => {
          error.response.data = JSON.parse(await error.response.data.text())
          throw error
        })
    },
    // index() {
    //   return [
    //     {
    //       name: 'название',
    //       edited: '10 окт 2022',
    //       size: '5 мб',
    //       count: '5',
    //     },
    //   ]
    // },
  })

  inject('systemAudienceRepository', {
    ...repositoryWithAxios('/sys/marketing/v1/campaigns/'),
    index: indexFormater(
      '/sys/marketing/v1/audiences/',
      normalizeToTableAudience,
      $axios
    ),

    show: showFormater(
      '/sys/marketing/v1/campaigns/',
      normalizeToSingleAudience,
      $axios
    ),
    getAudiences(params) {
      return $axios.$get(`/sys/marketing/v1/audiences/`, { params })
    },
    getCities(params) {
      return $axios.$get(`/sys/marketing/v1/audiences/filters/cities/`, {
        params,
      })
    },
    getReceiptsAvgAmount(params) {
      return $axios.$get(
        `/sys/marketing/v1/audiences/filters/receipts-average-amount-hist/`,
        {
          params,
        }
      )
    },
    getStores(params) {
      return $axios.$get(`/sys/marketing/v1/audiences/filters/stores/`, {
        params,
      })
    },
    getProductBrands(params) {
      return $axios.$get(
        `/sys/marketing/v1/audiences/filters/products-brands/`,
        {
          params,
        }
      )
    },
    getReceiptsQuantity(params) {
      return $axios.$get(
        `/sys/marketing/v1/audiences/filters/receipts-quantity-hist/`,
        {
          params,
        }
      )
    },
    getProductsCategories(params) {
      return $axios.$get(
        `/sys/marketing/v1/audiences/filters/products-categories/`,
        {
          params,
        }
      )
    },
    getUsersBehaviorTags(params) {
      return $axios.$get(
        `/sys/marketing/v1/audiences/filters/users-behaviour-tags/`,
        {
          params,
        }
      )
    },
    getUsersPersonalTags(params) {
      return $axios.$get(
        `/sys/marketing/v1/audiences/filters/users-personal-tags/`,
        {
          params,
        }
      )
    },
    getDiscountProductsPercent(params) {
      return $axios.$get(
        `/sys/marketing/v1/audiences/filters/discount-products-percent-hist/`,
        {
          params,
        }
      )
    },
    calculateAudienceSize(params) {
      return $axios.$post(`/sys/marketing/v1/audiences/calculate-size/`, params)
    },
    createAudience(params) {
      return $axios.$post(`/sys/marketing/v1/audiences/`, params)
    },
    deleteAudience(params) {
      return $axios.$delete(`/sys/marketing/v1/audiences/`, { params })
    },
    getAudience(audienceId) {
      return $axios.$get(`/sys/marketing/v1/audiences/${audienceId}/`)
    },
    editAudience(audienceId, params) {
      return $axios.$patch(`/sys/marketing/v1/audiences/${audienceId}/`, params)
    },
  })

  inject('systemPartnersRepository', {
    getPartners(params) {
      return $axios.$get(`/sys/erp/v1/partners/`, { params })
    },
    createPartner(params) {
      return $axios.$post(`/sys/erp/v1/partners/`, params)
    },
    getFilters() {
      return $axios.$get(`/sys/erp/v1/partners/filters/`)
    },
    getPartnerProfile(partner_code) {
      return $axios.$get(`/sys/erp/v1/partners/${partner_code}/profile/`)
    },
    editPartner(partner_code, editData) {
      return $axios.$patch(`/sys/erp/v1/partners/${partner_code}/profile/`, {
        ...editData,
      })
    },
    changeStatus(partner_code, status) {
      return $axios.$put(`/sys/erp/v1/partners/${partner_code}/status/`, {
        new_status: status,
      })
    },
    getLegalEntities(partner_code, params) {
      return $axios.$get(
        `/sys/erp/v1/partners/${partner_code}/legal-entities/`,
        { params }
      )
    },
    getLegalEntityInfo(partner_code, organization_id) {
      return $axios.$get(
        `/sys/erp/v1/partners/${partner_code}/legal-entities/${organization_id}/`
      )
    },
    createLegalEntity(partner_code, params) {
      return $axios.$post(
        `/sys/erp/v1/partners/${partner_code}/legal-entities/`,
        params
      )
    },
    editLegalEntity(partner_code, organization_id, editData) {
      return $axios.$patch(
        `/sys/erp/v1/partners/${partner_code}/legal-entities/${organization_id}/`,
        {
          ...editData,
        }
      )
    },
    getPartnerProducts(partner_code, params) {
      return $axios.$get(`/sys/erp/v1/partners/${partner_code}/products/`, {
        params,
      })
    },
    deletePartnerProducts(partner_code, params) {
      return $axios.$delete(`/sys/erp/v1/partners/${partner_code}/products/`, {
        params,
      })
    },
    getRetailers() {
      return $axios.$get(`/sys/erp/v1/user/allowed-partners/`)
    },
    addPartnerProductsBySku(partner_code, data) {
      return $axios.$patch(
        `/sys/erp/v1/partners/${partner_code}/products/by-retailer-SKU`,
        data
      )
    },
    addPartnerProductsByGtin(partner_code, data) {
      return $axios.$patch(
        `/sys/erp/v1/partners/${partner_code}/products/by-GTIN`,
        data
      )
    },
    getPartnerUsers(partner_code, params) {
      return $axios.$get(`/sys/erp/v1/partners/${partner_code}/users/`, {
        params,
      })
    },
    createPartnerUserAccount(partner_code, params) {
      return $axios.$post(
        `/sys/erp/v1/partners/${partner_code}/users/create/`,
        params
      )
    },
    addPartnerUserAccount(partner_code, params) {
      return $axios.$post(`/sys/erp/v1/partners/${partner_code}/users/`, params)
    },
    deletePartnerUser(partner_code, params) {
      return $axios.$delete(`/sys/erp/v1/partners/${partner_code}/users/`, {
        params,
      })
    },
    changePartnerUser(partner_code, userUuid, data) {
      return $axios.$patch(
        `/sys/erp/v1/partners/${partner_code}/users/${userUuid}/`,
        data
      )
    },
    deleteAudience(params) {
      return $axios.$delete(`/sys/marketing/v1/audiences/`, { params })
    },
  })
  inject('systemUserRepository', {
    deleteMyAccount() {
      return $axios.$delete(`${$config.baseURLShopping}/api/account/`)
    },
  })
  inject('systemClusterOffer', {
    getLastClusterOffer() {
      return $axios.$get(`/cluster-offers/sys/v1/plan-cluster-offers/`)
    },
    getWeekPlan(weekNumber) {
      return $axios.$get(`/cluster-offers/sys/v1/weeks/${weekNumber}/`)
    },
    downloadWeekPlan(weekNumber) {
      return $axios.$get(`/cluster-offers/sys/v1/weeks/${weekNumber}/`, {
        responseType: 'blob',
      })
    },
    planClusterOffer(payload = {}) {
      return $axios.$post(
        `/cluster-offers/sys/v1/plan-cluster-offers/`,
        payload
      )
    },
    //   V2 +++++++++++++++++++++++++++++++++++++++++++++++++++++
    getClusterOffersList(params) {
      return $axios.$get(`/cluster-offers/sys/v2/cluster-offers/`, params)
    },
    getClusterOffer(id) {
      return $axios.$get(`/cluster-offers/sys/v2/cluster-offers/${id}/`)
    },
    getClusterOfferWeekPlan(id, weekNumber) {
      return $axios.$get(
        `/cluster-offers/sys/v2/cluster-offers/${id}/weeks/${weekNumber}/`
      )
    },
    downloadClusterOfferWeekPlan(id, weekNumber) {
      return $axios.$get(
        `/cluster-offers/sys/v2/cluster-offers/${id}/weeks/${weekNumber}/`,
        {
          responseType: 'blob',
        }
      )
    },
    createClusterOffer(payload) {
      return $axios.$post(`/cluster-offers/sys/v2/cluster-offers/`, payload)
    },
    getFiltersCities() {
      return $axios.$get(`/cluster-offers/sys/v2/filters/cities/`)
    },
    async getFiltersMonths() {
      let monthList = []
      await $axios
        .$get(`/cluster-offers/sys/v2/filters/months/`)
        .then((res) => {
          monthList = normalizeMonthList(res.data)
        })
      return { data: monthList }
    },
  })
  inject('systemReferralRepository', {
    index: indexFormater(
      '/sys/marketing/promo-links/v1/links/',
      normalizeToTableReferral,
      $axios
    ),
    getReferral(id) {
      return $axios.$get(`/sys/marketing/promo-links/v1/links/${id}/`)
    },
    editReferral(id, payload) {
      return $axios.$patch(
        `/sys/marketing/promo-links/v1/links/${id}/`,
        payload
      )
    },
    addReferral(payload) {
      return $axios.$post(`/sys/marketing/promo-links/v1/links/`, payload)
    },
    removeReferral(id) {
      return $axios.$delete(`/sys/marketing/promo-links/v1/links/${id}/`)
    },
    getUsersList(id, params) {
      return $axios.$get(
        `/sys/marketing/promo-links/v1/links/${id}/referrals/`,
        { params }
      )
    },
    downloadReport(id) {
      return $axios
        .$get(`/sys/marketing/promo-links/v1/links/${id}/report/`, {
          responseType: 'blob',
        })
        .then((response) => {
          const blob = URL.createObjectURL(
            new Blob([response], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = blob
          link.download = `Реферальная программа (${id}).xlsx`
          link.click()
        })
    },
  })
  inject('systemGeoRepository', {
    // index: indexFormater('/geo/', normalizeToTableReferral, $axios),
    getOrdersAndStores(params) {
      return $axios.$get(`/geo/stores_orders/`, { params })
    },
    getCouriers(params) {
      return $axios.$get(`/geo/couriers/`, { params })
    },
    assignOrderToCourier(payload) {
      return $axios.$post(`/geo/couriers/`, payload)
    },
    removeOrderFromCourier(payload) {
      return $axios.$delete(`/geo/couriers/`, { data: payload })
    },
  })
}
