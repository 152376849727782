//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['APP_USER_ALLOWED_PARTNERS', 'APP_SELECTED_PARTNER']),
  },
  methods: {
    ...mapMutations(['SET_APP_SELECTED_PARTNER']),
    onSelectPartner(val) {
      localStorage.setItem('APP_SELECTED_PARTNER_ID', val.id)
      location.reload()
    },
    comparator(el, el2, el3) {
      return el.id === el2.id
    },
  },
}
