import { render, staticRenderFns } from "./AppChangePartner.vue?vue&type=template&id=68bd5b8d&"
import script from "./AppChangePartner.vue?vue&type=script&lang=js&"
export * from "./AppChangePartner.vue?vue&type=script&lang=js&"
import style0 from "./AppChangePartner.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RostSelect: require('/home/ilya/app/rost_web_frontend/components/rost/select.vue').default,RostIcon: require('/home/ilya/app/rost_web_frontend/components/rost/icon.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VMenu,VSheet})
